type ModelUrlsReturnType<T extends boolean> = T extends true
	? {
			detailsUrl: (id: string, projectId: string) => string
			editUrl: (id: string, projectId: string) => string
			listUrl: (projectId: string) => string
			newUrl: (projectId: string) => string
		}
	: {
			detailsUrl: (id: string) => string
			editUrl: (id: string) => string
			listUrl: () => string
			newUrl: () => string
		}

export function getModelUrls<T extends boolean>(
	lowerPlural: string,
	inProject: T,
	isAdmin?: boolean,
): ModelUrlsReturnType<T> {
	const baseUrl = `${isAdmin ? 'admin/' : ''}${lowerPlural.replace(/\s+/g, '')}`
	return inProject
		? ({
				detailsUrl: (id: string, projectId: string) =>
					`/projects/${projectId}/${baseUrl}/${id}`,
				editUrl: (id: string, projectId: string) =>
					`/projects/${projectId}/${baseUrl}/${id}/edit`,
				listUrl: (projectId: string) => `/projects/${projectId}/${baseUrl}`,
				newUrl: (projectId: string) => `/projects/${projectId}/${baseUrl}/new`,
			} as ModelUrlsReturnType<T>)
		: ({
				detailsUrl: (id: string) => `/${baseUrl}/${id}`,
				editUrl: (id: string) => `/${baseUrl}/${id}/edit`,
				listUrl: () => '/' + baseUrl,
				newUrl: () => `/${baseUrl}/new`,
			} as ModelUrlsReturnType<T>)
}
